import React, { useEffect, useState } from 'react';
import { LegislationCreatePayloadLegislation } from '../../../swagger/models/LegislationCreatePayloadLegislation';
import './AddOwnLegislation.scss';
import { AdoptechTextInput2 } from '../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { useDispatch, useSelector } from 'react-redux';
import { LegislationClassificationModel } from '../../../swagger/models/LegislationClassificationModel';
import {
  fetchClassifications,
  fetchLegalRegister,
  postLegislation,
} from '../../../store/legalRegister/legalRegisterThunks';
import { isValidUrl } from '../../../functions/isValidUrl';
import { AdoptechReactSelect2 } from '../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { AdoptechTextArea2 } from '../../../components/AdoptechTextArea2/AdoptechTextArea2';
import { ApplicationState } from '../../../types/applicationState';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { SharedLegislationRecurring } from '../../../swagger/models/SharedLegislationRecurring';
import { updateAddLegislationForm } from '../../../store/legalRegister/legalRegisterSlice';
import {
  AddLegislationForm,
  useAfterLegislationFormSubmit,
} from './useAddLegislationFormHandler';

interface AddOwnLegislationProps {
  addHandler: ReturnType<typeof useAddOwnLegislationHandler>;
}
export const validation = (
  data: OwnLegislationForm
): Record<string, string[]> => ({
  name: (data.name || '').trim().length > 0 ? null : ['Name must be present'],
  legislationClassificationId: data.legislationClassificationId
    ? null
    : ['Classification must be set'],
  summary:
    (data.summary || '').trim().length > 0 ? null : ['Summary must be present'],
  jurisdictionId: data.jurisdictionId ? null : ['Jurisdiction must be set'],
  descriptionUrl:
    !data.descriptionUrl || isValidUrl(data.descriptionUrl)
      ? null
      : ['Link must be valid URL'],
  description:
    (data.description || '').trim().length > 0
      ? null
      : ['Description must be present'],
});
interface OwnLegislationForm
  extends Partial<LegislationCreatePayloadLegislation> {}
export const useAddOwnLegislationHandler = (props: { onClose: () => void }) => {
  const dispatch = useDispatch();

  const { addLegislationForm } = useSelector(
    (state: ApplicationState) => state.legalRegister
  );
  const afterSubmit = useAfterLegislationFormSubmit(props.onClose);

  const jurisdictions = addLegislationForm.jurisdictions;
  const [classifications, setClassifications] =
    useState<LegislationClassificationModel[]>(null);

  const [formData, setFormData] = useState<OwnLegislationForm>({});

  const hasError = (field: string) => !!validation(formData)[field];

  const classificationOptions = () =>
    (classifications || []).map(c => ({
      label: c.name,
      value: c.id,
    }));

  const jurisdictionOptions = () =>
    (jurisdictions || []).map(j => ({
      label: j.name,
      value: j.id,
    }));

  const formValidation = validation(formData);
  const formValid = Object.values(formValidation).some(value => value != null);

  const onSubmit = async () => {
    const {
      name,
      legislationClassificationId,
      summary,
      jurisdictionId,
      descriptionUrl,
      description,
    } = formData;
    const payload: Required<LegislationCreatePayloadLegislation> = {
      name,
      legislationClassificationId,
      summary,
      jurisdictionId,
      descriptionUrl,
      description,
      explanationOfCompliance: null,
      recurring: SharedLegislationRecurring.Annually,
    };
    await dispatch(postLegislation(payload));
    afterSubmit();
  };
  return {
    formData,
    setFormData,
    classificationOptions,
    jurisdictionOptions,
    hasError,
    jurisdictions,
    formValid,
    setClassifications,
    onSubmit,
  };
};
export const AddOwnLegislation: React.FC<AddOwnLegislationProps> = props => {
  const baseCss = 'addOwnLegislation';
  const headerCss = 'documentLibraryHeader';
  const {
    formData,
    setFormData,
    classificationOptions,
    jurisdictionOptions,
    hasError,
    jurisdictions,
    setClassifications,
  } = props.addHandler;
  const dispatch = useAppDispatch();
  useEffect(() => {
    setFormData({});
    dispatch(fetchClassifications(data => setClassifications(data)));
  }, []);

  return (
    <div className={baseCss}>
      <div className={headerCss + '--titleRow'}>
        <div className={headerCss + '--title'}>Add my own legislation</div>
      </div>
      <div className={baseCss + '--mainColumns'}>
        <div className={baseCss + '--overviewSection'}>
          <div className={baseCss + '--sectionTitle'}>Overview</div>
          <div className={baseCss + '--sectionContent'}>
            <div className={baseCss + '--field'}>
              <AdoptechTextInput2
                id="legislation-nameInput"
                value={formData.name}
                type="text"
                hasError={hasError('name')}
                rounded
                onChange={e => {
                  setFormData({ ...formData, name: e.currentTarget.value });
                }}
                label="Legislation name"
                placeholder="Example Legislation"
              />
            </div>
            <div className={baseCss + '--field'}>
              <AdoptechReactSelect2
                id="legislation-classificationInput"
                options={classificationOptions()}
                hasError={hasError('legislationClassificationId')}
                rounded
                onChange={e => {
                  setFormData({
                    ...formData,
                    legislationClassificationId: e.value,
                  });
                }}
                value={classificationOptions().find(
                  o => o.value === formData.legislationClassificationId
                )}
                label="Classification"
                placeholder="Select classification"
              />
            </div>
            <div className={baseCss + '--field'}>
              <AdoptechReactSelect2
                id="legislation-jurisdictionInput"
                options={jurisdictionOptions()}
                hasError={hasError('jurisdictionId')}
                rounded
                onChange={e => {
                  setFormData({
                    ...formData,
                    jurisdictionId: e.value,
                  });
                }}
                value={jurisdictionOptions().find(
                  o => o.value === formData.jurisdictionId
                )}
                label="Jurisdiction"
                placeholder="Select jurisdiction"
              />
            </div>
            <div className={baseCss + '--field'}>
              <AdoptechTextInput2
                id="legislation-linkInput"
                value={formData?.descriptionUrl}
                type="text"
                hasError={hasError('descriptionUrl')}
                rounded
                onChange={e => {
                  setFormData({
                    ...formData,
                    descriptionUrl: e.currentTarget.value,
                  });
                }}
                label="URL link"
                placeholder="Enter URL Address"
              />
            </div>
            <div className={baseCss + '--field'}>
              <AdoptechTextArea2
                id="legislation-summaryInput"
                value={formData?.summary}
                hasError={hasError('summary')}
                rounded
                onChange={e => {
                  setFormData({ ...formData, summary: e.currentTarget.value });
                }}
                label="Summary"
                rows={4}
                placeholder="This is an example of creating a custom legislation"
              />
            </div>
          </div>
        </div>
        <div className={baseCss + '--descriptionSection'}>
          <div className={baseCss + '--sectionTitle'}>Description</div>
          <div className={baseCss + '--sectionContent'}>
            <div className={baseCss + '--field'}>
              <AdoptechTextArea2
                id="legislation-descriptionInput"
                value={formData?.description}
                placeholder="Enter more details of the legislation"
                hasError={hasError('description')}
                rounded
                onChange={e => {
                  setFormData({
                    ...formData,
                    description: e.currentTarget.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
