import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { MessageToast } from '../../MessageToast/MessageToast';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';
import {
  deletePestelItem,
  fetchPestelRegister,
  fetchPestels,
  patchPestelRegister,
} from '../../../store/pestel/pestelThunks';
import {
  clearToastMessage,
  setPestelIdToBeDeleted,
  setShowEditDrawer,
  updatePestelItemForm,
} from '../../../store/pestel/pestelSlice';
import { PestelSection } from '../PestelSection/PestelSection';
import {
  PestelItemArea,
  PestelRegisterUpdatePayload,
  VendorEventEventType,
} from '../../../swagger';
import './PestelPage.scss';
import { GenericModal } from '../../GenericModal/GenericModal';
import { selectFilteredPestels } from '../../../selectors/selectFilteredPestels';
import { ManagePestelItemsDrawer } from '../../ManagePestelItemsDrawer/ManagePestelItemsDrawer';
import { canFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { RegistryHeader } from '../../RegistryHeader/RegisterHeader';
import { AdoptechTextInput } from '../../AdoptechTextInput/AdoptechTextInput';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { AdoptechPanel } from '../../AdoptechPanel/AdoptechPanel';
import { EditActionModal } from '../../EditActionModal/EditActionModal';
import { useRegistryCreateActionRHS } from '../../../features/legalRegister/LegalRegisterPage/LegalRegisterPage';
import { useBackground } from '../../../hooks/useBackground';
import { Lozenge } from '../../Lozenge/Lozenge';

export const PestelPage: React.FC = () => {
  const baseCss = 'pestelPage';
  const baseTableCss = 'adoptechGridTable';
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string>('');

  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );

  const vendorUser = useSelector(selectCurrentVendorUser);
  const {
    toastMessage,
    toastVariant,
    isFetchingPestels,
    isFetchingPestelRegister,
    pestelItemForm,
    pestelRegister: register,
    pestelIdToBeDeleted: pestelIdToDelete,
    pestelItems: pestels,
    showEditDrawer: isShowingManageDrawer,
  } = useSelector((state: ApplicationState) => state.pestel);

  const { createAction, setShowCreateAction, showCreateAction } =
    useRegistryCreateActionRHS({
      registerType: 'PestelRegister',
      register,
    });

  const pestelsFilteredBySearch = useSelector(
    selectFilteredPestels(searchText)
  );
  const setIsShowingManageDrawer = (value: boolean) =>
    dispatch(setShowEditDrawer(value));
  useBackground('grey');
  useEffect(() => {
    dispatch(fetchPestelRegister());
    dispatch(fetchPestels(vendorId));
  }, [vendorId, vendorUser.roles]);

  const canManageRegister = canFeature(AccessObject.pestel_manage);

  if (isFetchingPestels) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className={baseCss}>
        {register && (
          <RegistryHeader
            setShowCreateAction={setShowCreateAction}
            setIsShowingAddDrawer={itemType => {
              dispatch(updatePestelItemForm({ itemType }));
              dispatch(setShowEditDrawer(true));
            }}
            isLoadingList={isFetchingPestels || isFetchingPestelRegister}
            register={register}
            registerType="PestelRegister"
            canManageRegister={canManageRegister}
            onChange={(opts: PestelRegisterUpdatePayload) =>
              dispatch(patchPestelRegister(opts))
            }
            additionalHeaderRows={
              <AdoptechPanel
                borderRadius="8px 8px 0px 0px"
                padding="16px 16px 0px 16px"
              >
                <div className={baseCss + '--searchRow'}>
                  <div className={baseCss + '--registryTitle'}>
                    <div className={baseCss + '--columnLabel'}>
                      Risks and Opportunities
                    </div>
                    <Lozenge value={register.stats?.pestelItemsCount} />
                  </div>
                  <AdoptechTextInput
                    id="search"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    type="text"
                    placeholder="Search for risks and opportunities"
                    icon={faSearch}
                    additionalClass="adoptechTextInput-search"
                  />
                </div>
              </AdoptechPanel>
            }
          />
        )}
        <AdoptechPanel borderRadius="0px" padding="1px 16px 16px 16px">
          <MessageToast
            autohide
            variant={toastVariant}
            delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
            onClose={() => dispatch(clearToastMessage())}
            show={Boolean(toastMessage)}
          >
            {toastMessage}
          </MessageToast>
          {Object.values(PestelItemArea).map(key => {
            return (
              <div key={key}>
                <PestelSection
                  pestelsFilteredBySearch={pestelsFilteredBySearch}
                  itemArea={key}
                  baseTableCss={baseTableCss}
                />
              </div>
            );
          })}
        </AdoptechPanel>
        {isShowingManageDrawer && (
          <ManagePestelItemsDrawer
            onClose={() => setIsShowingManageDrawer(false)}
          />
        )}
        {showCreateAction && (
          <EditActionModal
            confirm={createAction}
            init={{ actionType: VendorEventEventType.PestelReview }}
            show={showCreateAction}
            close={() => setShowCreateAction(false)}
            disabledFields={['actionType']}
          />
        )}
      </div>
      <GenericModal
        closeButtonLabel="CONFIRM"
        onCancel={() => dispatch(setPestelIdToBeDeleted(undefined))}
        onClose={() => {
          dispatch(deletePestelItem(pestelIdToDelete));
        }}
        show={!!pestelIdToDelete}
        showCancelButton
        title="Confirm delete?"
      >
        <br />
        <h3>{pestels.find(p => p.id === pestelIdToDelete)?.description}</h3>
        <br />
        <div>
          Click CONFIRM to permanently delete this{' '}
          {`${pestels.find(p => p.id === pestelIdToDelete)?.itemType}`}.
        </div>
      </GenericModal>
    </>
  );
};
