import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '../../../types/grid';
import {
  deleteLegislation,
  fetchLegalRegister,
  fetchVendorLegislations,
  patchLegalRegister,
} from '../../../store/legalRegister/legalRegisterThunks';
import { selectLegislations } from '../../../selectors/selectLegislations';
import AdoptechGridTable from '../../../components/AdoptechGridTable/AdoptechGridTable';
import { SortableTableHeader } from '../../../components/SortableTableHeader/SortableTableHeader';
import { NoDataText } from '../../../components/NoDataText/NoDataText';
import { AdoptechPanel } from '../../../components/AdoptechPanel/AdoptechPanel';
import './LegalRegister.scss';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import {
  LegalRegisterModel,
  LegalRegisterUpdatePayload,
  LegislationModel,
  PestelRegisterModel,
  ReferenceCreatePayloadReferenceReferenceableTypeEnum,
  VendorActionExtended,
  VendorEventEventType,
} from '../../../swagger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditLegislationDrawer } from '../EditLegislationDrawer/EditLegislationDrawer';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink';
import { GenericModal } from '../../../components/GenericModal/GenericModal';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import { ApplicationState } from '../../../types/applicationState';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { canFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import classNames from 'classnames';
import { setLegislationsSearch } from '../../../store/legalRegister/legalRegisterSlice';
import { EditActionModal } from '../../../components/EditActionModal/EditActionModal';
import { showGlobalToast } from '../../../store/global/globalSlice';
import {
  RegistryHeader,
  RegistryHeaderProps,
} from '../../../components/RegistryHeader/RegisterHeader';
import {
  createActionReference,
  createNewAction,
} from '../../calendar/store/calendarThunks';
import { AdoptechCircleIcon } from '../../../components/AdoptechCircleIcon/AdoptecCircleIcon';
import { fetchPestelRegister } from '../../../store/pestel/pestelThunks';
import { useBackground } from '../../../hooks/useBackground';
import { Lozenge } from '../../../components/Lozenge/Lozenge';
import { mapVendorActonFormToPostPayload } from '../../calendar/CalendarPage/CalendarPageModals';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { AddLegislationModal } from '../AddLegislationModal/AddLegislationModal';

export interface DeleteLegislationInfo {
  id: string;
  name: string;
}

const LegislationList: React.FC<{
  items?: LegislationModel[];
  onClick(l: LegislationModel): void;
  setDeleteLegislation: (legislation: DeleteLegislationInfo) => void;
  isLoadingList: boolean;
}> = ({ items, onClick, setDeleteLegislation, isLoadingList }) => {
  const canManageRegister = canFeature(AccessObject.legal_register_manage);
  if (isLoadingList) return <LoadingSpinner />;
  if (items.length < 1) return <NoDataText text="No legislation to show" />;
  const baseCss = 'legalRegister';

  return (
    <AdoptechGridTable header={<></>}>
      {items.map(l => (
        <div
          key={l.id}
          className={classNames('adoptechGridTable--row', {
            disabled: !canManageRegister,
          })}
          onClick={() => canManageRegister && onClick(l)}
        >
          <AdoptechCircleIcon
            variant={l.jurisdiction.identifier === 'ot' ? 'green' : 'lightBlue'}
          >
            {l.jurisdiction.identifier}
          </AdoptechCircleIcon>
          <div className={baseCss + '--name'}>{l.name}</div>
          <FontAwesomeIcon
            onClick={e => {
              e.stopPropagation();
              window.open(l.descriptionUrl, '_blank');
            }}
            icon={faExternalLink}
          />
          <div>{l.legislationClassification.name}</div>

          <div
            className={classNames(
              baseCss + '--padding-right-xs',
              baseCss + '--summary'
            )}
          >
            {l.summary}
          </div>

          {canManageRegister && (
            <div className="adoptechTable--actions">
              <FontAwesomeIcon
                onClick={e => {
                  e.stopPropagation();
                  setDeleteLegislation({ id: l.id, name: l.name });
                }}
                icon={faTrashAlt}
              />
            </div>
          )}
        </div>
      ))}
    </AdoptechGridTable>
  );
};

export const useRegistryCreateActionRHS = ({
  register,
  registerType,
}: {
  register: LegalRegisterModel | PestelRegisterModel;
  registerType: RegistryHeaderProps['registerType'];
}) => {
  const [showCreateAction, setShowCreateAction] = useState(false);
  const dispatch = useAppDispatch();
  const afterCreate = (action: VendorActionExtended) => {
    dispatch(
      createActionReference(
        action.id,
        register?.id,
        registerType === 'LegalRegister'
          ? ReferenceCreatePayloadReferenceReferenceableTypeEnum.LegalRegister
          : ReferenceCreatePayloadReferenceReferenceableTypeEnum.PestelRegister,
        () => {
          if (registerType === 'LegalRegister') dispatch(fetchLegalRegister());
          if (registerType === 'PestelRegister')
            dispatch(fetchPestelRegister());
        }
      )
    );
    dispatch(
      showGlobalToast(
        'Action has been successfully created. Please visit the Actions & Events page to see it.'
      )
    );
  };

  const createAction = async (params: VendorActionExtended) => {
    const payload = mapVendorActonFormToPostPayload(params);
    const newAction = await dispatch(createNewAction({ payload })).unwrap();
    afterCreate(newAction);
    setShowCreateAction(false);
  };
  return { createAction, setShowCreateAction, showCreateAction };
};

export const LegalRegisterPage: React.FC = () => {
  const dispatch = useDispatch();
  const [legislationInfo, setLegislationInfo] =
    useState<DeleteLegislationInfo>();
  const baseCss = 'legalRegister';
  const [isShowingAddModal, setIsShowingAddDrawer] = useState<boolean>(false);
  const search = useSelector(
    (state: ApplicationState) => state.legalRegister.legislationsSearch
  );

  const currentVendor = useSelector(selectCurrentVendor);

  useEffect(() => {
    dispatch(fetchLegalRegister());
    dispatch(fetchVendorLegislations());
  }, [currentVendor]);
  const canManageRegister = canFeature(AccessObject.legal_register_manage);
  const legislationsList = useSelector(selectLegislations);
  const isLoadingList = useSelector(
    (state: ApplicationState) =>
      state.legalRegister.isRequestingVendorLegislations ||
      state.legalRegister.isFetchingLegalRegister
  );
  const register = useSelector(
    (state: ApplicationState) => state.legalRegister.legalRegister
  );
  const { createAction, setShowCreateAction, showCreateAction } =
    useRegistryCreateActionRHS({
      registerType: 'LegalRegister',
      register,
    });
  const [editDrawerShowReason, setEditDrawerShowReason] =
    useState<LegislationModel>(null);
  useBackground('grey');

  if (!isLoadingList && !register)
    return (
      <div style={{ marginTop: '20px' }}>
        No legal register exist for the current user
      </div>
    );

  return (
    <div className={baseCss}>
      {register && (
        <RegistryHeader
          setShowCreateAction={setShowCreateAction}
          setIsShowingAddDrawer={setIsShowingAddDrawer}
          isLoadingList={isLoadingList}
          register={register}
          canManageRegister={canManageRegister}
          registerType="LegalRegister"
          onChange={(opts: LegalRegisterUpdatePayload) =>
            dispatch(patchLegalRegister(opts))
          }
          additionalHeaderRows={
            <AdoptechPanel borderRadius="8px 8px 0px 0px">
              <div className={baseCss + '--searchRow'}>
                <div className={baseCss + '--registryTitle'}>
                  <div className={baseCss + '--columnLabel'}>Legislation</div>
                  <Lozenge value={register.stats?.legislationsCount} />
                </div>
                <AdoptechTextInput
                  id="search"
                  value={search}
                  onChange={e =>
                    dispatch(setLegislationsSearch(e.currentTarget.value))
                  }
                  type="text"
                  placeholder="Search for legislation"
                  icon={faSearch}
                  additionalClass="adoptechTextInput-search"
                />
              </div>
              <div className="adoptechGridTable--header">
                <SortableTableHeader<LegislationModel>
                  columnName="jurisdiction"
                  grid={Grid.LegalRegister}
                  label="Jurisd."
                  notInTable
                />
                <SortableTableHeader<LegislationModel>
                  columnName="name"
                  grid={Grid.LegalRegister}
                  label="Legislation"
                  notInTable
                />
                <span>Link</span>
                <span>Classification</span>
                <span>Summary</span>
              </div>
            </AdoptechPanel>
          }
        />
      )}
      <AdoptechPanel padding="0px 16px 16px 16px" borderRadius="0px">
        <LegislationList
          items={legislationsList}
          onClick={setEditDrawerShowReason}
          setDeleteLegislation={setLegislationInfo}
          isLoadingList={isLoadingList}
        />
      </AdoptechPanel>
      {isShowingAddModal && (
        <AddLegislationModal onClose={() => setIsShowingAddDrawer(false)} />
      )}
      <GenericModal
        closeButtonLabel="CONFIRM"
        onCancel={() => setLegislationInfo(undefined)}
        onClose={() => {
          dispatch(deleteLegislation(legislationInfo?.id));
          setLegislationInfo(undefined);
        }}
        show={!!legislationInfo}
        showCancelButton
        title="Confirm delete?"
      >
        <br />
        <h3>{legislationInfo?.name}</h3>
        <br />
        <div>Click CONFIRM to permanently delete this legislation.</div>
      </GenericModal>
      <EditLegislationDrawer
        showReason={editDrawerShowReason}
        onClose={() => setEditDrawerShowReason(null)}
      />

      {showCreateAction && (
        <EditActionModal
          confirm={createAction}
          init={{ actionType: VendorEventEventType.LegalRegisterReview }}
          show={showCreateAction}
          disabledFields={['actionType']}
          close={() => setShowCreateAction(false)}
        />
      )}
    </div>
  );
};
