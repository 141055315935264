import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LegislationExtended, LegislationModel } from '../../../swagger';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import './EditLegislationDrawer.scss';

// Sections
import AdoptechHS from '../../../components/AdoptechHS/AdoptechHS';
import {
  fetchLegislation,
  updateLegislation,
} from '../../../store/legalRegister/legalRegisterThunks';
import {
  OverviewSection,
  validation as overviewValidation,
} from './OverviewSection';
import {
  DetailsSection,
  validation as detailsValidation,
} from './DetailsSection';

interface Props {
  showReason: LegislationModel;
  onClose: (updateNeeded: boolean) => void;
}

export const EditLegislationDrawer: React.FC<Props> = ({
  showReason,
  onClose,
}) => {
  const baseCss = 'editLegislationDrawer';

  const dispatch = useDispatch();

  const [formData, setFormData] = useState<LegislationExtended>(null);

  // track changes presence
  const [originalData, setOriginalData] = useState(formData);
  const formHasChanges = formData != originalData;

  const initData = (data: LegislationExtended) => {
    setFormData(data);
    setOriginalData(data);
  };

  const cleanData = () => {
    setFormData(null);
    setOriginalData(null);
  };

  useEffect(() => {
    if (!showReason?.id) return cleanData();

    dispatch(fetchLegislation(showReason.id, initData));
  }, [showReason]);

  const validation = !!formData && {
    ...overviewValidation(formData),
    ...detailsValidation(formData),
  };
  const formValid =
    !!validation &&
    Object.values(validation).reduce((acc, v) => acc && !v, true);
  const title = 'Edit Legislation';

  const dispatchSubmit = () => {
    dispatch(
      updateLegislation(
        formData.id,
        {
          name: formData.name,
          legislationClassificationId: formData.legislationClassification.id,
          summary: formData.summary,
          jurisdictionId: formData.jurisdiction.id,
          descriptionUrl: formData.descriptionUrl,
          description: formData.description,
        },
        () => onClose(true)
      )
    );
  };

  const drawerBackgroundRef = useRef<HTMLDivElement>();

  const onCloseAndCleanup = () => {
    cleanData();
    onClose(true);
  };
  return (
    <AdoptechHS
      noFormPadding
      title={title}
      show={!!showReason}
      ref={drawerBackgroundRef}
      showConfirmationWarning={formHasChanges}
      onClose={onCloseAndCleanup}
      footer={
        <>
          <AdoptechButton
            onClick={() => drawerBackgroundRef.current.click()}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={dispatchSubmit}
            variant={AdoptechButtonVariant.Primary}
            disabled={!formValid || !formHasChanges}
          >
            Confirm
          </AdoptechButton>
        </>
      }
      extraClass="adoptechHS--medium editLegislationHS"
    >
      {formData ? (
        <div className={baseCss + '--formContainer'}>
          <OverviewSection formData={formData} setFormData={setFormData} />
          <DetailsSection formData={formData} setFormData={setFormData} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </AdoptechHS>
  );
};
