import classNames from 'classnames';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './AddLegislationModal.scss';
import AdoptechModal from '../../../components/AdoptechModal/AdoptechModal';
import { AddNewLegislation } from './AddNewLegislation';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { useAddLegislationFormHandler } from './useAddLegislationFormHandler';
import {
  AddOwnLegislation,
  useAddOwnLegislationHandler,
} from './AddOwnLegislation';

const Tab = {
  register: 'register',
  own: 'own',
};

interface AddLegislationModalProps {
  onClose: () => void;
}
export const AddLegislationModal: React.FC<
  AddLegislationModalProps
> = props => {
  const [selectedTab, setSelectedTab] = useState(Tab.register);
  const baseCss = 'documentLibraryModal';
  const tabClasses = (tab: string) =>
    classNames('documentLibraryModal--tab', { selected: tab === selectedTab });

  const addHandler = useAddLegislationFormHandler({ onClose: props.onClose });
  const addOwnHandler = useAddOwnLegislationHandler({ onClose: props.onClose });
  const { isSubmitting, onSubmit, submitButtonDisabled, onTabChange } =
    addHandler;

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
    onTabChange();
  };
  return (
    <AdoptechModal show onHide={props.onClose} className="documentLibraryModal">
      <Modal.Header>
        <div>Legislation library</div>
        <div className="documentLibraryModal--tabs">
          <div
            className={tabClasses(Tab.register)}
            onClick={() => handleTabChange(Tab.register)}
          >
            Legislation
          </div>
          <div
            className={tabClasses(Tab.own)}
            onClick={() => handleTabChange(Tab.own)}
          >
            Add my own
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {selectedTab === Tab.register && (
          <AddNewLegislation addHandler={addHandler} />
        )}
        {selectedTab === Tab.own && (
          <AddOwnLegislation addHandler={addOwnHandler} />
        )}
      </Modal.Body>

      <Modal.Footer>
        <AdoptechButton
          onClick={props.onClose}
          variant={AdoptechButtonVariant.White}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          busy={isSubmitting}
          disabled={
            selectedTab === Tab.register
              ? submitButtonDisabled
              : addOwnHandler.formValid
          }
          onClick={
            selectedTab === Tab.register ? onSubmit : addOwnHandler.onSubmit
          }
          variant={AdoptechButtonVariant.Primary}
        >
          Add
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
