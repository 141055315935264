import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faUndo } from '@fortawesome/pro-light-svg-icons/faUndo';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClauseLastUpdatedInfo } from '../../functions/getClauseLastUpdatedInfo';
import { patchVendorPolicyClause } from '../../store/policies/policiesThunks';
import { Question, VendorPolicyAnswer } from '../../swagger/models';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import CustomizeClauseWarningModal from '../CustomizeClauseWarningModal/CustomizeClauseWarningModal';
import { GenericModal } from '../GenericModal/GenericModal';
import { LastUpdatedInfo } from '../LastUpdatedInfo/LastUpdatedInfo';
import { PolicyQuestions } from '../PolicyQuestions/PolicyQuestions';
import './PolicyCategoryRow.scss';
import { ApplicationState } from '../../types/applicationState';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

interface PolicyCategoryRowProps {
  allowCustomText: boolean;
  answers: VendorPolicyAnswer[];
  categoryName: string;
  clauseText: string;
  leaveOut: boolean;
  ownClause: boolean;
  questions: Question[];
  updatedAt: string;
  updatedBy: string;
  vendorPolicyClauseId: string;
  vendorPolicyId: string;
  progress: number;
}

export const PolicyCategoryRow: React.FC<PolicyCategoryRowProps> = props => {
  const dispatch = useDispatch();

  const [ownClause, setOwnClause] = useState(props.ownClause);
  const [showWarning, setShowWarning] = useState(false);
  const [showIncompleteWarning, setShowIncompleteWarning] = useState(false);

  const lastUpdated = getClauseLastUpdatedInfo(
    props.updatedAt,
    props.updatedBy
  );

  const isPatching = useSelector(
    (state: ApplicationState) => state.policies.isPatchingVendorPolicyClause
  );

  const handleCustomizeClick = () => {
    dispatch(
      patchVendorPolicyClause(props.vendorPolicyClauseId, {
        vendorPolicyId: props.vendorPolicyId,
        clauseTextIsCustom: !ownClause,
      })
    );
    setOwnClause(!ownClause);
    setShowWarning(false);
  };

  const handleToggleClick = () => {
    if (ownClause) {
      setShowWarning(true);
      return;
    }
    props.progress !== 1
      ? setShowIncompleteWarning(true)
      : handleCustomizeClick();
  };

  const someQuestionsAreTable = (props.questions || []).some(
    question => question.layout === 'table_layout'
  );
  return (
    <React.Fragment>
      <PolicyQuestions {...props} viewModelLocal={!ownClause} />
      <div className="policyCategoryRow--footer">
        {props.allowCustomText && (
          <>
            <LastUpdatedInfo
              title="Clause last updated"
              lastUpdatedInfo={lastUpdated}
              customText={
                (props.questions?.length === 0 &&
                  !ownClause &&
                  'Adoptech generated standard text') ||
                (props.questions?.length > 0 &&
                  !ownClause &&
                  'Your answers generate the text for this clause. Click Edit to view and modify this clause')
              }
            />
            <div className="policyCategoryRow--options">
              {!someQuestionsAreTable && (
                <AdoptechButton
                  disabled={props.leaveOut || isPatching}
                  icon={ownClause ? faUndo : faPen}
                  onClick={handleToggleClick}
                  variant={AdoptechButtonVariant.White}
                >
                  {isPatching ? (
                    <LoadingSpinner inlineSmall />
                  ) : ownClause ? (
                    'Revert'
                  ) : (
                    'Edit'
                  )}
                </AdoptechButton>
              )}
              <CustomizeClauseWarningModal
                toggleClicked={showWarning}
                hide={() => setShowWarning(false)}
                onOk={handleCustomizeClick}
              />
              <GenericModal
                show={showIncompleteWarning}
                onClose={() => setShowIncompleteWarning(false)}
                title="View/Edit clause"
                closeButtonLabel="Got it"
                className="policyCategoryRow--incompleteWarningModal"
              >
                Please complete all questions to view and edit this clause.
              </GenericModal>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};
